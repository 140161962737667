$(document).ready(function () {

    'use strict';
    
    // Scroll to anchor links and tour nav anchors

    var parPosition = [];
    $('.tour-page').each(function() {
        parPosition.push($(this).offset().top);
    });

    $('.anchor').click(function(){

      $('html, body').animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
    }, 300);
      return false;




  });

    


// Reveal Sign in form overlay


$('.js-overlay-button').click(function () {
    var $overlayContainer = $('#signinOverlay');

    var overlayId;

    overlayId = $(this).data('overlay-target');

    $overlayContainer.addClass('active');
    $overlayContainer.find('.overlay').removeClass('active');
    $overlayContainer.find('[data-overlay="' + overlayId + '"]').addClass('active');

    $('html').addClass('overlay-open');



});

$('.js-overlay-close').click(function () {
    $('.overlay-container').removeClass('active');
    $('overlay-container').find('.overlay').removeClass('active');

    $('html').removeClass('overlay-open');

});

// initialise iframe source after documentready, otherwise we're showing a spinner
// for ever
$(function() {
    setTimeout(function() {
        $('#wizardOverlay').find('iframe').attr("src", "/wizard");
    }, 500)
});

// pop out the wizzard when any get-started button is clicked
$('.get-started').click(function () {
    var $wizardContainer = $('#wizardOverlay');
    var overlayId;

    overlayId = $(this).data('overlay-target');

    $wizardContainer.addClass('active');
    $wizardContainer.find('.overlay').removeClass('active');
    $wizardContainer.find('[data-overlay="' + overlayId + '"]').addClass('active');

    $('html').addClass('overlay-open');



});



// Initialise main slider

// $('#home-slider').slick({
//  autoplay: false,
//  dots: true,
//  arrows: true,
//  fade: true
// });


// Initialise affix function for tour nav position on scroll
$('.tour-nav').affix({
    offset: {
        top: $('.header').outerHeight(true),
        bottom: $('.footer').outerHeight(true) + $('.trial-feature').outerHeight(true)
    }
});


// Initialise tooltip for tour nav
if(!('ontouchstart' in window))
{
  $('[data-toggle="tooltip"]').tooltip({
    trigger: 'hover'
});
}


// Initialise popovers for pricing table
$(function () {
  $('[data-toggle="popover"]').popover({
    trigger: 'click hover'
  });
});


// Placehoders for old browesers
$('input, textarea').placeholder();


});   



// Initialise wow animations

var wow = new WOW({
    boxClass: 'wow', // animated element css class (default is wow)
    animateClass: 'animated', // animation css class (default is animated)
    offset: 0, // distance to the element when triggering the animation (default is 0)
    mobile: false, // trigger animations on mobile devices (default is true)
    live: true // act on asynchronously loaded content (default is true)
    
});
wow.init();

function ContactSubmit()
{

    $.ajax({
      type: "POST",
      url: 'https://backstagelive.co/api_v2/turnstyle-website/landing_page_contact_form/',
      data: {"data": {"goran":"new"}},
      contentType: "application/json",
      success: function() {
        console.log("hello")
      }
    });
    
    return false;

}

function SignIn()
{
  console.log("here");
  console.log(firebase);
  var form = document.getElementById('signinForm'); 
  var email = form.elements['username'].value;
  var password = form.elements['password'].value

    var data = {
      email: email, 
      password: password,
    }
    $.ajax({
      type: "POST",
      url: 'https://backstagelive.co/api_v2/rest-auth/login/',
      data: data,
      dataType: 'json',
      success: function(result) {
        console.log(result.key);
        localStorage.setItem('auth', result.key);
        localStorage.setItem('username', email);
        localStorage.setItem('backend', 'bsl');
        window.location.href = '/dashboard/';
      },
      error: function(result) {
        console.log('try firebase as a backend');
        firebase.auth().signInWithEmailAndPassword(email, password).then(function(user) {
          localStorage.setItem('auth', user.uid);
          localStorage.setItem('email', user.email);
          localStorage.setItem('password', password);
          localStorage.setItem('backend', 'firebase');
          window.location.href = '/dashboard/';          
        });
      }
    });
    
    return false;

}


